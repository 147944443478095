@import 'variables';
/* Vendor – Bootstrap */
@import '../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/variables';
@import '../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/mixins';
@import '../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/normalize';
@import '../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/scaffolding';
@import '../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/type';
@import '../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/grid';
@import '../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/utilities';
@import '../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/responsive-utilities';
@import '../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/forms';
@import '../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/input-groups';
@import '../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/buttons';
@import '../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/button-groups';
@import '../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/alerts';
@import '../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/panels';
@import '../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/tables';
@import '../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/modals';
@import '../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/close';
@import '../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/tooltip';

@import '../../node_modules/slick-carousel/slick/slick.scss';
@import '../../node_modules/jquery.mmenu/src/core/offcanvas/jquery.mmenu.offcanvas';
@import '../../node_modules/jquery.mmenu/src/core/oncanvas/jquery.mmenu.oncanvas';
@import "sass-space";
/* Base */
@import 'base/mixins';
@import 'base/normalize';
@import 'base/helper-class';
@import 'base/scaffolding';

// Import Modules
@import '../_modules/header/header';
@import '../_modules/footer/footer';
@import '../_modules/product/product';
@import '../_modules/pagination/pagination';
@import '../_modules/registration/registration';

@import "aside";
@import "order";
@import "user";

/* ===========================================
 * General styles
 * ======================================== */
html {
    font-size: 14px;
    //
    //@media (min-width: 400px) {
    //    font-size: 14px;
    //}

    @media (min-width: $screen-md-min) {
        font-size: 15px;
    }

    @media (min-width: $screen-lg-min) {
        font-size: 16px;
    }
}

body {
    font-size: 12px;
}

/* ===========================================
 * Ext Bootstrap
 * ======================================== */
a {
    &:focus {
        outline: none;
    }
}

.btn-secondary {
    @include button-variant(#FFFFFF, $brand-secondary, $brand-secondary);
}

.btn-secondary-light {
    @include button-variant($brand-secondary, #f2f6ff, #f2f6ff);
}

.tab-content {
    @include clearfix;

    > * {
        visibility: hidden;
        height: 0;
        display: none;

        &.active {
            visibility: visible;
            height: auto;
            display: block;
        }
    }

    &--clear {
        border: none;
        padding: 0;
        background: transparent;

        .submit-container {
            padding-right: 0;
            padding-top: 1rem;
        }
    }
}

.nav-tabs:not(.nav-tabs--header) {
    @include reset;
    @include clearfix;
    font-size: 1.125rem;
    display: flex;

    li {
        display: inline-block;
        float: left;
        margin-right: 5px;
        border: solid #007AB9;
        border-width: 1px 1px 0 1px;
        position: relative;
        border-radius: 5px 5px 0 0;
        max-width: 33%;

        a {
            position: relative;
            padding: .6rem 1.25rem;
            color: $brand-secondary-light-text;
            @include ellipsis;
            display: block;

        }

        &.active {
            background-color: #EDEEF5;

            &:after {
                content: '';
                left: 0;
                right: 0;
                bottom: -1px;
                height: 1px;
                background-color: #EDEEF5;
                position: absolute;
            }

            a {
                color: $brand-secondary;

                &:after,
                &:before {
                    content: '';
                    @include square(10px);
                    position: absolute;
                    bottom: -1px;
                    border: solid #007AB9;
                    z-index: 2;
                }

                &:before {
                    right: 100%;
                    border-bottom-right-radius: 5px;
                    border-width: 0 1px 1px 0;
                    box-shadow: 2px 2px 0 #EDEEF5;

                }

                &:after {
                    left: 100%;
                    border-bottom-left-radius: 5px;
                    border-width: 0 0 1px 1px;
                    box-shadow: -2px 2px 0 #EDEEF5;
                }
            }

            &:first-child {
                a:before {
                    display: none;
                }
            }
        }
    }
}

.tab-content:not(.tab-content--header):not(.tab-content--clear) {
    clear: both;
    background-color: #EDEEF5;
    padding: 1.5rem 1rem 2rem 1rem;
    border: 1px solid #007AB9;
    border-radius: 0 5px 5px 5px;
    font-family: $font-family-sans-serif;
}

.tab-content--clear {
    fieldset {
        margin-bottom: 1rem;
    }
    hr {
        margin-top: 1rem;
        margin-bottom: 1.5rem;
    }

    @media (min-width: $screen-sm-min) {
        .tab-pane {
            padding-left: 20px;
            padding-right: 20px;

            hr {
                margin-left: -20px;
                margin-right: -20px;
            }
        }
    }
}
/* Table */
.table-responsive {

    @media screen and (max-width: $screen-xs-max) {
        border-width: 1px 0 0 0;

        > .table-bordered {

            // Nuke the appropriate borders so that the parent can handle them
            > thead,
            > tbody,
            > tfoot {
                > tr {
                    > th:first-child,
                    > td:first-child {
                        border-left: 1px solid $table-border-color;
                    }
                    > th:last-child,
                    > td:last-child {
                        border-right: 1px solid $table-border-color;
                    }
                }
            }

            // Only nuke the last row's bottom-border in `tbody` and `tfoot` since
            // chances are there will be only one `tr` in a `thead` and that would
            // remove the border altogether.
            > tbody,
            > tfoot {
                > tr:last-child {
                    > th,
                    > td {
                        border-bottom: 1px solid $table-border-color;
                    }
                }
            }
        }
    }
}

th {
    font-weight: normal;
    font-size: 12px;
    border-bottom-width: 1px !important;
    background-color: #f2f6ff;
}

/* Grid */
.row-flex {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    &:before {
        display: block;
    }
}

.col-sm--3 {
    padding-left:  ceil(($grid-gutter-width / 2));
    padding-right: floor(($grid-gutter-width / 2));

    @media (min-width: $screen-sm-min) {
        width: 33.333%;
        float: left;
    }
}
/* Modal */

.modal-header {
    font-size: 2.25rem;
    font-family: $font-family-headline;
    position: relative;
    padding-bottom: 13px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 2.2rem;
    border: none;
    line-height:1;

    &:after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: 3px;
        border: solid $gray-lighter;
        border-width: 1px 0;
        display: block;
    }

    span:first-child {
        flex: 1;
    }

    .close {
        position: absolute;
        right: 15px;
        top: 5px;

    }
}

/* Panel */
.panel-body {
    @media (max-width: $screen-xs-max) {
        padding-left: 1.2rem;
        padding-right: 1.2rem;
    }
}

/* Tooltip */
.product__container {
    @include clearfix;
    .tooltip-inner {
        width: 300px;
        padding: 5px;

        @media (min-width: $screen-sm-min) {
            @include square(300px);
        }

        @media (min-width: $screen-md-min) {
            @include square(430px);
        }

        @media (min-width: $screen-lg-min) {
            @include square(450px);
        }
    }
}



/* ===========================================
 * Layout
 * ======================================== */
.wrapper,
#wrapper{
    overflow: hidden;
}

body:not(.page-home) #container {
    @extend .container;
}


.col-two #container {
    margin-top: -1.5rem;

    #main,
    #left {
        padding-top: 30px;
    }

    #left {
        margin-bottom: $vr;
        border-top: dashed 2px $gray-lighter;

        @media (min-width: $screen-sm) {
            padding-left: 20px;
            padding-right: 20px;
            float: left;
            width: 26.5%;
            border-top: none;
        }
    }

    #main {
        border-left: solid $gray-lighter;
        border-width: 0 0 1px 0;
        margin-bottom: $vr;

        @media (min-width: $screen-sm) {
            padding-left: 20px;
            padding-right: 20px;
            float: right;
            width: 73.5%;
            border-width: 0 0 0 1px;
        }
    }
}

.page-list #main {
    padding-left: 0;
}

/* ===========================================
 * Fonts
 * ======================================== */
@import "../fonts/webfontkit/stylesheet.css";
@import "../fonts/icomoon/style.scss";

.browsehappy {
    text-align: center;
    background-color: $brand-primary;
    padding: 1rem;
    color: #ffffff;

    a {
        color: inherit;
        text-decoration: underline;
    }
}
/* ===========================================
* Section
* ======================================== */
section {
    padding-bottom: 2rem;
}

.bg-gradient {
    padding-top: 2.2rem;
    position: relative;
/*    @include gradient-repeating-linear(); */

    &:before {
        content: '';
        height: 8px;
        left: 0;
        right: 0;
        position: absolute;
        top: 0;

    }

    hr {
        border-color: rgba(0, 0, 0, .05);
    }

    .headline-section {
        border-color: #FFFFFF;
    }
}

/* ===========================================
 * Slick
 * ======================================== */
.slick-list {
    .slick-loading & {
        background: #fff slick-image-url("../images/ajax-loader.gif") center center no-repeat;
    }
}

/* Arrows */
.slick-arrow {
    margin-left: -5px;
    margin-right: -5px;
    + .slick-list {
        margin-left: 2.2rem;
        margin-right: 2rem;
    }
    @media (min-width: $screen-sm-min) {
        + .slick-list {
            margin-left: 4rem;
            margin-right: 4rem;
        }
    }
}

.slick-prev,
.slick-next {
    @include transition(color .3s);
    height: 4rem;
    font-size: 3.2rem;
    position: absolute;
    display: block;
    line-height: 4rem;
    margin-top: -3rem;
    cursor: pointer;
    background: transparent;
    top: 50%;
    border: none;
    outline: none;
    font-family: $font-family-headline;
    color: $brand-secondary-light-text;

    &:hover {
        color: $brand-secondary;
        border-color: $brand-secondary;
    }

    &.slick-disabled {
        color: #C2C2C2;
        border-color: #C2C2C2;
    }
}

.slick-prev {
    left: 0;
    @include rotateY(180deg)
}

.slick-next {
    right: 0;
}

.carousel-nav {
    position: relative;
    text-align: center;
    padding-top: .9rem;

    .slick-prev,
    .slick-next {
        position: static;
        display: inline-block;
        @include square(3.25rem);
        line-height: 0;
        border: 1px solid #C2C2C2;
        padding: 0 0 5px;
        background: #FFFFFF;
        margin: 0 1px;
    }
}

/* Dots */

.slick-dots {
    @include reset;
    margin: 5px 0;
    display: block;
    text-align: center;
    width: 100%;

    li {
        @include square(12px);
        position: relative;
        display: inline-block;
        margin: 0 5px;
        padding: 0;
        cursor: pointer;
        border: 3px solid #C2C2C2;
        border-radius: 50%;

        button {
            display: none;
        }

        &.slick-active {
            @include gradient-vertical(#F2F2F2, $brand-primary);
            border-color: #FFFFFF;
            box-shadow: 0 1px 1px rgba(0, 0, 0, .2);
        }
    }

    @media (min-width: $screen-sm-min) {
        margin: 0;
        position: absolute;
        bottom: -25px;

    }
}

/* ===========================================
 * Headline
 * ======================================== */
h4 {
    font-weight: bold;
}

.headline {
    font-family: $font-family-headline;
    margin-bottom: .9rem;
}

.headline-page {
    font-size: 2.25rem;
    font-family: $font-family-headline;
    position: relative;
    padding-bottom: 13px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 2.2rem;

    &:after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: 3px;
        border: solid $gray-lighter;
        border-width: 1px 0;
    }

    &__count {
        color: $brand-secondary-light-text;
        font-size: 0.66666em;
    }

    &--no-border {
        margin-bottom: 0;
        &:after {
            display: none;
        }
    }

    &--col-one {
        &:before {
            display: none;
        }

        @media (min-width: $screen-md-min) {
            padding-left: 20px;
            padding-right: 20px;
        }
    }

    &__text-right {
        float: right;
        font-family: $font-family-sans-serif;
        font-size: 12px;
        padding: 5px 0;
        text-align: right;
    }

    @media (min-width: $screen-sm-min) {
        &:before {
            content: '';
            position: absolute;
            left: -21px;
            width: 1px;
            top: 0;
            bottom: 5px;
            background-color: #f2f6ff;
        }
    }
}

.headline-section {
    border-bottom: 1px solid $gray-lighter;
    font-family: $font-family-headline;
    font-size: 2.25rem;
    margin-bottom: 1.3rem;
    padding-bottom: 3px;

    &__title,
    &__count {
        color: $brand-secondary-light-text;
    }

    &__count {
        font-size: 1.5rem;
    }

    &--md {
        font-size: 1.625rem;
    }
}

.headline-label {
    font-size: 1.275rem;
    color: $brand-secondary-light-text;
    font-family: $font-family-serif;
    line-height: .86;
    margin-bottom: 1.2rem;

    &--md {
        font-size: 1.5rem;
    }

    &--lg {
        font-size: 2.25rem;
    }
}

.page-text {
    .headline-page {
        font-family: $font-family-sans-serif;
        font-weight: 600;
        font-size: 1.5rem;
        padding-top: 5px;
    }
}
/* ===========================================
 * Form
 * ======================================== */
label {
    font-weight: normal;
}

.form-inline label {
    padding-right: 20px;
}

.form-control {
    border-color: #e5e5e5;
    border-top-color: #cbcbcb;
    box-shadow:  inset 0 1px 0 #ededed;

    &[type="number"] {
        padding-right: 2px;
        padding-left: 8px;
    }
}

.form-flex {
    display: flex;
    flex-wrap: nowrap;
}

.checkbox,
.radio {
    $this: &;
    line-height: 1.2;

    & + & {
        margin-top: 15px;
    }

    &:last-child {
        //margin-top: 0;
        //margin-bottom: 0;
    }

    label,
    span.label {
        padding-left: 25px;
        position: relative;
        padding-top: 2px;
        min-height: 10px;

        &:before,
        &:after {
            visibility: visible;
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            @include square(16px);
            color: $brand-secondary;
        }

        &:before {
            border-radius: 2px;
            border: 1px solid #DDEDF2;
            border-top-color: #e8e8e8;
            //box-shadow: 0 1px 0 rgba(0, 0, 0, .1), inset 0 1px 0 #e8e8e8;
            background-color: #fff;
            box-shadow: inset 2px 2px 5px rgba(0, 0, 0, .1);
        }

        &:after {
            text-align: center;
            font-size: 14px;
            line-height: 14px;
        }
    }

    input {
        visibility: hidden;
        top: 0;
        left: 0;
        margin: 0 !important;
        height: 16px;
        position: absolute;
        outline: none !important;

        &:checked + label {
            //color: $brand-primary;

            &:after {
                content: 'x';
                font-family: $font-family-serif;
                font-weight: bold;
            }
        }
    }

    &--checked-bold {
        input:checked + label {
            font-weight: bold;
        }
    }

    &--display-inline-block {
        display: inline-block;

        &:not(:last-child) {
            padding-right: 20px;
        }

        label {
            &:after,
            &:before {
                top: 50%;
                margin-top: -8px !important;
            }
        }

        input:checked + label {
            color: $brand-primary;
        }
    }
}

.radio {


    label {
        &:before {
            border-radius: 50%;
        }
    }

    input:checked + label:after {
        content: '' !important;
        margin-left: 4px;
        margin-top: 4px;
        @include square(8px);
        background-color: $brand-secondary;
        border-radius: 50%;
    }

    &--display-inline-block {
        input:checked + label:after {
            margin-top: -4px !important;
        }
    }


}

input, .btn {
    font-family: $font-family-serif;
}

.input-group-btn {
    font-size: inherit;
}

/* Dropdown */
.dropdown {
    background: $brand-secondary-lighter;
    position: relative;

    &:after {
        content: '\e902';
        font-family: icomoon;
        font-size: .8em;
        line-height: 30px;
        width: 29px;
        height: 29px;
        text-align: center;
        position: absolute;
        top: 50%;
        margin-top: -14.5px;
        right: 0;
        border-left: 1px solid #f2f6ff;
        z-index: 0;
    }

    select {
        float: none !important;
        padding-right: 38px;
        background: transparent !important;
        width: 100%;
        border: none;
        box-shadow: none;
        background-image: none;
        -webkit-appearance: none !important;
        outline: none;
        cursor: pointer;
        position: relative;
        z-index: 3;

        /* Hide arrow on FF */
        -moz-appearance: none !important;
        text-indent: 0.01px;
        text-overflow: ellipsis;

        &:focus {
            //display: none !important;
            z-index: 1 !important;
        }

        &::-ms-expand {
            display: none;
        }

        optgroup {
            color: inherit;
            font: inherit;
        }
    }
}

%form-safe {
    @media (min-width: $screen-sm-min) {
        position: relative;
        @include clearfix;

        > * {
            overflow: hidden;
        }

        &:before {
            content: '\e917';
            font-family: icomoon;
            position: relative;
            left: 0;
            width: 37%;
            text-align: center;
            font-size: 12rem;
            line-height: 1;
            color: #DDEDF2;
            float: left;
        }
    }
}

.form--password,
.form--login {
    @extend %form-safe;
}
.form--login {
    &:before {
        width: 23.5%;
        font-size: 9rem;
    }

    .form-group {
        margin-bottom: 2px;
    }

    .btn {
        margin-top: .5rem;
    }

    a {
        display: inline-block;
    }
}


.row-postal {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    max-width: 180px;

    &__separator {
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 10px;
        font-size: 14px;
    }

    input {
        max-width: 70px;
    }
}

.input--postal {
    max-width: 80px;
}

legend {
    border-bottom: none;
    font-family: $font-family-serif;
    color: $brand-secondary-light-text;
    font-size: 1.75rem;
    line-height: 1;
    margin-bottom: 1rem;
}

fieldset {
    margin-bottom: 1.4rem;
}