// registration styles
.nav-registration {
    border-bottom: 1px solid $gray-lighter;
    margin-bottom: 1.5rem;
    font-family: $font-family-serif;

    &__label {
        color: #f2f6ff;
        font-size: 2.25rem;
        margin-bottom: .5rem;

        @media (min-width: $screen-sm-min) {
            padding-left: 20px;
        }

    }

    ul {
        @include reset;
        display: flex;
        justify-content: space-between;
        max-width: 480px;
        line-height: 1;
    }

    a {
        color: $brand-secondary;
    }

    &__name {
        min-height: 30px;
        small {
            display: block;
            color: $brand-secondary-light-text;
            font-size: 12px;
        }
    }

    li {
        position: relative;
        display: inline-block;
        text-align: center;
        font-size: 1.125rem;
        float: left;
        margin-bottom: -1px;
        min-width: 80px;
        padding-bottom: 10px;

        * {
            text-align: center;
        }

        .radio {
            margin-top: 0;
            margin-left: 7px;
        }

        i, span:not(.radio) {
            display: block;
        }

        i {
            color: $brand-secondary-light-text;
            font-size: 20px ;
            margin-bottom: 5px;
        }

        &:after {
            content: '';
            position: absolute;
        }

        &.active {
            border-bottom: 1px solid $brand-primary;

            a {
                color: $brand-primary;
            }

            &:before {
                content: '';
                position: absolute;
                @include triangle(16px, $brand-primary, down);
                top: 100%;
                left: 50%;
                margin-left: -8px;
            }

            &:after {
                content: '';
                position: absolute;
                @include triangle(14px, #FFFFFF, down);
                top: 100%;
                left: 50%;
                margin-left: -7px;
            }
        }
    }

}