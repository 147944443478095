/* Mixins */
@mixin reset {
    margin: 0;
    padding: 0;
    background: transparent;
    list-style: none;
    border: none;
}

@mixin before() {
    content: '';
    position: absolute;
}

@mixin bg-sprite($width: 0, $height: 0, $bg-left: 0, $bg-top: 0) {
    background-image: url("../images/sprite.png");
    width: $width;
    height: $height;
    background-position: $bg-left $bg-top;
}

@mixin icon($position: before, $icon: '', $margin: '', $color: '') {
    &:#{$position} {
        @if $icon {
            content: $icon;
            font-family: 'icomoon' !important;
            speak: none;
            font-style: normal;
            font-weight: normal;
            font-variant: normal;
            text-transform: none;
            line-height: 1;

            /* Better Font Rendering =========== */
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
        }

        @if $margin {
            @if ($position == before) {
                margin-right: $margin;
            }
            @if ($position == after) {
                margin-left: $margin;
            }
        }

        @if $color {
            color: $color;
        }
    }
}

@mixin indentText() {
    position: relative;
    text-indent: 200%;
    overflow: hidden;
    white-space: nowrap
}

@mixin ellipsis() {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

@mixin maskLink() {
    @include indentText();
    position: absolute;
    left: 0;
    right: 0;
    top:0;
    bottom: 0;
}

@mixin link-variant($color, $hovercolor: null,  $textdecoration: null) {


    @if ($color) {
        color: $color;
    } @else {
        color: inherit;
    }

    @if ($hovercolor) {
        &:hover {
            color: $hovercolor;
        }
    } @else {

        @if ($color == inherit) {
            &:hover {
                opacity: .8;
            }
        } @else {
            &:hover {
                color: darken($color, 20%);
            }
        }
    }

    @if ($textdecoration) {
        &:hover,
        &:focus,
        &:active {
            text-decoration: $textdecoration;
        }
    } @else {
        &:hover,
        &:focus,
        &:active {
            text-decoration: none;
        }
    }
}

/* Vertical align */
@mixin vertical-align($position: relative) {
    position: $position;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

@mixin vertical-align-parent() {
    -webkit-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    transform-style: preserve-3d;
}


/*  Animate */
@mixin keyframes($animationName) {
    @-webkit-keyframes #{$animationName} {
        @content;
    }
    @-moz-keyframes #{$animationName} {
        @content;
    }
    @-o-keyframes #{$animationName} {
        @content;
    }
    @keyframes #{$animationName} {
        @content;
    }
}

/* Gradient */
@mixin gradient-repeating-linear($angle: 90deg, $width: 25px, $color: $brand-secondary-lighter, $color-2: $brand-secondary-light) {
    background: $color;
    /*    background: moz-repeating-linear-gradient(
                    $angle,
                    $color,
                    $color 25px,
                    $color-2 25px,
                    $color-2 50px
    );
    background: -webkit-repeating-linear-gradient(
                    $angle,
                    $color,
                    $color 25px,
                    $color-2 25px,
                    $color-2 50px
    );
    background: -o-repeating-linear-gradient(
                    $angle,
                    $color,
                    $color 25px,
                    $color-2 25px,
                    $color-2 50px
    );*/

    background: repeating-linear-gradient(
                    $angle,
                    $color,
                    $color 25px,
                    $color-2 25px,
                    $color-2 50px
    );
}

/* triangle */
@mixin triangle($size, $color, $direction) {
    $width: nth($size, 1);
    $height: nth($size, length($size));
    $foreground-color: nth($color, 1);
    $background-color: if(length($color) == 2, nth($color, 2), transparent);
    height: 0;
    width: 0;

    @if ($direction == up) or ($direction == down) or ($direction == right) or ($direction == left) {
        $width: $width / 2;
        $height: if(length($size) > 1, $height, $height/2);

        @if $direction == up {
            border-bottom: $height solid $foreground-color;
            border-left: $width solid $background-color;
            border-right: $width solid $background-color;
        } @else if $direction == right {
            border-bottom: $width solid $background-color;
            border-left: $height solid $foreground-color;
            border-top: $width solid $background-color;
        } @else if $direction == down {
            border-left: $width solid $background-color;
            border-right: $width solid $background-color;
            border-top: $height solid $foreground-color;
        } @else if $direction == left {
            border-bottom: $width solid $background-color;
            border-right: $height solid $foreground-color;
            border-top: $width solid $background-color;
        }
    } @else if ($direction == up-right) or ($direction == up-left) {
        border-top: $height solid $foreground-color;

        @if $direction == up-right {
            border-left:  $width solid $background-color;
        } @else if $direction == up-left {
            border-right: $width solid $background-color;
        }
    } @else if ($direction == down-right) or ($direction == down-left) {
        border-bottom: $height solid $foreground-color;

        @if $direction == down-right {
            border-left:  $width solid $background-color;
        } @else if $direction == down-left {
            border-right: $width solid $background-color;
        }
    } @else if ($direction == inset-up) {
        border-color: $background-color $background-color $foreground-color;
        border-style: solid;
        border-width: $height $width;
    } @else if ($direction == inset-down) {
        border-color: $foreground-color $background-color $background-color;
        border-style: solid;
        border-width: $height $width;
    } @else if ($direction == inset-right) {
        border-color: $background-color $background-color $background-color $foreground-color;
        border-style: solid;
        border-width: $width $height;
    } @else if ($direction == inset-left) {
        border-color: $background-color $foreground-color $background-color $background-color;
        border-style: solid;
        border-width: $width $height;
    }
}