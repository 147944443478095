// header styles
$header-background: #007AB9;

#header {
//    @include gradient-repeating-linear();
    margin-bottom: 1.5rem;
    position: relative;
}

.page-home #header {
    margin-bottom: 2.2rem;
}

body:not(.page-home) #header {
    &:after {
        content: '';
        bottom: -8px;
        height: 16px;
        left: 0;
        right: 0;
        position: absolute;
        background-position: center top;
        background-repeat: repeat-x;
    }
}

.top-bar {
    background-color: #f2f6ff;
    text-align: center;
    display: flex;
    align-items: center;
    position: relative;

    &__container {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;

        > * {
            flex: 2
        }
    }

    &__status {
        flex: 1;
        display: inline-block;
        position: relative;
        font-size: 1.125rem;
        font-family: $font-family-serif;
        padding-top: .3rem;
        color: $brand-secondary-light-text;
        white-space: nowrap;
        width: 150px;
        min-width: 150px;
        max-width: 150px;

        &:before {
            @include triangle(150px 12px, #FFFFFF, down);
            content: '';
            position: absolute;
            z-index: 2;
            top: 100%;
            left: 50%;
            margin-left: -75px;
        }

        @include icon(after, '\f107', '', $brand-primary);
        &:after {
            position: absolute;
            bottom: -9px;
            left: 50%;
            width: 20px;
            text-align: center;
            margin-left: -9px;
            z-index: 2;
        }
    }

    &__nav {
        font-size: 11px;
        white-space: nowrap;

        ul, li {
            @include reset();
        }

        li {
            display: inline-block;

            &:not(:first-child) {
                margin-left: 15px;
            }
        }

        a {
            @include link-variant($brand-secondary-light-text);
        }

        &--left {
            text-align: left;
        }

        &--right {
            text-align: right;
            flex: 2;
        }
    }

    &__fb {
        flex: 1;

        @media (max-width: $screen-xs-max) {
            position: absolute;
            right: 20px;
            top: 62px;
            padding-left: 5px;
        }
    }

}

.header-logo {
    float: left;
    font-family: $font-family-headline;
    font-size: 2.25rem;
    padding-top: 5px;

    a {
        text-decoration: none;
        color: $brand-secondary;
    }
}

.header-container {
    text-align: right;
    font-family: $font-family-serif;
    font-size: 1.375rem;
    line-height: 1.1;
    padding-top: 1.5rem;
    padding-bottom: 1.4rem;
}

/* Header : Cart */
.header-cart {
    $this: &;
    vertical-align: top;
    position: relative;
    padding-left: 3.5rem;
    display: inline-block;
    text-align: left;
    margin-right: 3.2rem;

    &__icon {
        position: absolute;
        left: 0;
        top: .3rem;
        font-size: 2.4rem;
    }

    &__text {
        margin-bottom: .1rem;
    }

    &__count {
        position: absolute;
        top: -5px;
        left: 100%;
        margin-left: 12px;
        background-color: $brand-primary;
        color: #FFFFFF;
        padding: 3px 4px;
        font-size: 1.125rem;
    }

    &__price {
        color: $brand-primary;
        font-size: 1.125rem;
    }

    @media (max-width: $screen-xs-max) {
        display: none;
    }

    &--mobile {
        position: relative;

        #{$this}__count {
            font-size: .9rem;
            margin-left: -8px;
            top: -8px;
            padding: 3px;
            line-height: 1;
        }
    }
}

/* Header : Account */
.header-account {
    display: none;
    @media (min-width: $screen-sm-max) {
        display: inline-block;
        vertical-align: top;
        text-align: left;
        position: relative;
        padding-left: 3rem;
        padding-right: 1rem;

        &__icon {
            position: absolute;
            left: 0;
            top: .3rem;
            font-size: 2.4rem;
        }

        &__text {
            margin-bottom: .1rem;
        }

        &__links {
            @include reset;
            font-size: 1.125rem;

            li {
                display: inline-block;

                &:not(:first-child) {
                    margin-left: .6rem;
                    padding-left: .9rem;
                    border-left: 1px solid #9E9E9E;
                }
                a {
                    @include link-variant($brand-secondary-light-text)
                }
            }
        }
    }
}

/* Header : Form */
.header-form {
    display: none;
    position: relative;
    background-color: $header-background;
    padding: 10px 20px;
    font-family: $font-family-serif;

    &__select {
        width: 21.5%;
        min-width: 80px;
        margin-right: 2px;
        border-right: 2px solid #6E6E6E;
        box-shadow: 1px 0 rgba(0, 0, 0, .1);
        position: relative;
        z-index: 3;
    }

    .form-control,
    .btn {
        font-size: 1.375rem;
        height: 2.5rem;
        vertical-align: top;
        padding-top: 0;
        padding-bottom: 0;
        line-height: 1;
        border: none;
    }

    .form-control,
    .dropdown {
        box-shadow: none;
        background-color: #FFFFFF;
        color: #000;

        select option {
            color: #000;
            background: #FFFFFF;
        }
    }

    .btn {
        border-radius: 0;
        padding: 0 15px;
        margin-left: 0.625rem;
    }

    &__text {
        position: relative;
        @include clearfix;

        @include icon(before, '\f002');

        &:before {
            position: absolute;
            z-index: 10;
            height: 2.5rem;
            line-height: 2.5rem;
            width: 2.375rem;
            text-align: center;
            color: #FFF;
            font-size: 1rem;
        }

        .form-control {
            padding-left: 2.375rem;
        }
    }

    &__link {
        font-family: $font-family-sans-serif;
        padding-left: 30px;

        a {
            font-size: 11px;
            @include link-variant($brand-secondary-light-text)
        }
    }

    @media (min-width: $screen-sm-min) {
        display: block !important;
    }

    @media (max-width: $screen-xs-max) {
        padding-left: 10px;
        padding-right: 10px;

        .input-group {
            padding-bottom: 30px;
        }

        &__link {
            padding-left: 0;
            display: block;
            position: absolute;
            bottom: 0;
            right: 0;
            width: 100%;
            text-align: right;
        }
    }
}

/* Header navigation */
.header-nav {
    display: none;

    @media (min-width: $screen-sm-min) {
        display: block;
        background-color: #f2f6ff;
        font-family: $font-family-serif;
        font-size: 1rem;
        padding: 0 20px;
        position: relative;
        z-index: 99;

        ul, li {
            @include reset;
        }

        nav > ul {
            @include clearfix;
        }

        nav > ul > li {
            display: inline-block;
            float: left;

            > a {
                display: block;
                padding: 11px 14px 14px 14px;
                @include ellipsis;
                position: relative;
                @include link-variant($brand-secondary, $brand-primary);

                &.active {
                    &:after {
                        content: '';
                        position: absolute;
                        left: 14px;
                        right: 14px;
                        bottom: 0;
                        height: 3px;
                        background-color: $brand-primary;
                        border-radius: 3px 3px 0 0;
                    }
                }
            }

            &.first {
                width: 21.5%;
                margin-right: 1rem;

                > *:first-child {
                    width: 100%;

                    &:after {
                        content: '';
                        position: absolute;
                        width: 2px;
                        top: 15px;
                        bottom: 15px;
                        right: 0;
                        background-color: #E6E6E6;
                    }
                }
            }

            &.submenu {
                > *:first-child {
                    position: relative;
                    @include icon(before, '\f107');

                    &:before {
                        position: absolute;
                        left: 50%;
                        width: 20px;
                        text-align: center;
                        margin-left: -20px;
                        bottom: 5px;
                        font-size: .8em;
                        color: #DADADA;
                    }
                }
            }
        }
    }
}

li:hover > .header-nav__megamenu {
    display: block;
}

.header-nav__megamenu {
    display: none;
    position: absolute;
    box-shadow: inset 0 3px 1px rgba(0, 0, 0, .3);
    left: 0;
    right: 0;
    background-color: $brand-secondary;
    color: #FFFFFF;
    font-size: 1rem;
    padding-bottom: 20px;
    border-radius: 0 0 5px 5px;

    a {
        color: inherit;
        text-shadow: 0 1px 0 rgba(0, 0, 0, .2);

        i {
            text-shadow: none;
        }
    }
}

.nav-promo {
    border-bottom: 1px solid rgba(255, 255, 255, .1);
    padding: 20px 20px 0 20px;
    margin-bottom: 1.2rem;

    a {
        @include link-variant(#FFFFFF, #FFFFFF);
        display: block;
        position: relative;
        text-transform: uppercase;
        font-size: 0.825rem;
        line-height: 1;
        padding: .6rem 2rem .6rem 1.2rem;
        margin-bottom: 20px;
        @include ellipsis();

        @include icon(after, '\e90b');

        &:after {
            float: right;
            position: absolute;
            right: 1.2rem;
        }

        &:before {
            @include transition(opacity .35s);
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            opacity: 0;
            background-color: rgba(255, 255, 255, .2);
        }

        &:hover {
            &:before {
                opacity: 1;
            }
        }
    }
}

.megamenu__container {
    padding-left: 20px;
    padding-right: 20px;
    line-height: 1.1;
    color: #CECECE;
    text-shadow: 0 1px rgba(#000, .2);

    a {
        @include link-variant($brand-secondary, $brand-primary);
    }

    ul, li {
        @include reset;
    }

    li {
        position: relative;
        margin-bottom: 8px;

        > a {
            :hover {
                color: #e41840;
            }
        }

        > a,
        > span {
            color: inherit;
            display: inline-block;
            padding-left: 20px;
            @include icon(before, '\e91d');

            &:before {
                left: 0;
                top: .3em;
                font-size: .7em;
                position: absolute;
                text-shadow: none;
            }

        }
    }
}

/* Header : Tabs */
.tab-content--header {
    padding-top: 20px;

    > * {
        display: block !important;
    }
}

.header-tabs {
    overflow: hidden;
}

.nav-tabs--header {
    @include reset;

    position: relative;
    display: inline-block;
    float: left;
    font-family: $font-family-serif;
    font-size: 1.375rem;
    color: #FFFFFF;
    line-height: 1;
    padding-top: 20px;

    &:before,
    &:after {
        content: '';
        position: absolute;
        right: 100%;
        top: 20px;
        bottom: 0;
        width: 1000px;
        background: $header-background;
    }
    &:after {
        right: auto;
        left: 100%;
    }

    &__quantity {
        font-size: .8em;
    }

    li {
        float: left;
        position: relative;
        border-bottom: 10px solid $header-background;

        @media (max-width: $screen-xs-max) {
            width: 33.3333%;
        }

        a {
            line-height: 1;
            display: block;
            padding: .5rem 1rem .3rem 1rem;
            position: relative;
            color: inherit;
            text-decoration: none;
            border-bottom: 4px solid transparent;
            background-color: $header-background;
            margin-bottom: -1px;

            @include ellipsis;
        }

        &.active {
            a {
                background-color: transparent;
                border-bottom-color: $brand-secondary;
                color: $brand-secondary;
            }

            &:after,
            &:before {
                content: '';
                height: 8px;
                right: 100%;
                width: 2000px;
                position: absolute;
                top: -8px;
            }

            &:after {
                left: 100%;
                background-position: center top;
            }
        }
    }

    &__count {
        font-size: .8em;
    }

    @media (min-width: $screen-lg-min) {
        //margin-left: -20px;
    }

    @media (max-width: $screen-xs-max) {
        margin-top: $vr;
        font-size: 1.5rem;
    }
}

/* Breadcrumbs */
.breadcrumbs {
    @include reset();
    font-family: $font-family-serif;
    font-size: 14px;
    padding: .5rem 0 1rem 1rem;

    li {
        display: inline-block;

        &:first-child {
            margin-right: 5px;
        }

        &:not(:first-child) {
            margin-left: 5px;
            &:before {
                content: '»';
                margin-right: 5px;
            }
        }
    }

    a {
        @include link-variant($brand-secondary, '');
        text-decoration: underline;
    }
}

/* Mobile Bar*/

.mobile-bar {
    margin-top: 3rem;
    @include clearfix;
    clear: both;
    text-align: center;
    background-color: #f2f6ff;
    font-size: 1.5rem;
    color: #FFFFFF;
    line-height: 1;

    a {
        @include link-variant(#FFFFFF, $brand-secondary);
        display: block;
        padding: 15px 5px;
        &.active,
        &:hover {
            background: $header-background;
        }
    }
    ul {
        @include reset;
    }

    li {
        float: left;
        width: 20%;

        &:not(:first-child) {
            border-left: 1px solid rgba(255, 255, 255, .2);
        }
    }


    @media (min-width: $screen-sm-min) {
        display: none;

    }
}

/* Mobile nav */
.mobile-nav {
    display: none;
    background-color: $header-background;
    font-family: $font-family-serif;
    font-size: 1.3rem;

    ul, li {
        @include reset();
    }

    li:not(:last-child) {
        border-bottom: 1px solid rgba(255, 255, 255, .2);
    }

    a {
        @include link-variant(#FFFFFF, $brand-secondary);
        display: block;
        padding: 5px 10px 5px 20px;
    }

    @media (min-width: $screen-sm-min) {
        display: none !important;
    }
}

/* Header order status */
.top-status {
    @include transition(max-height .4s);
    transition-timing-function: ease-in;
    max-height: 0;
    overflow: hidden;
    background-color: $brand-secondary;
    color: #f2f6ff;
    padding: 0 1rem;
    font-family: $font-family-serif;
    line-height: 1.1;
    font-size: 1.125rem;

    &__inside {
        margin: 5px auto;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
    }

    &__text {
        position: relative;
        @include icon(before, '\e91e');
        padding-left: 4.7rem;
        display: flex;
        align-items: center;
        font-size: 1.375rem;
        color: $brand-primary;
        white-space: nowrap;
        min-width: 240px;
        padding-right: 20px;
        height: 60px;

        small {
            color: #FFFFFF;
            display: block;
        }

        &:before {
            color: #FFFFFF;
            position: absolute;
            left: 0;
            top: 0;
            opacity: .1;
            font-size: 3.9rem;
        }

        @media (min-width: $screen-lg-min) {
            margin-left: -320px;
        }
    }

    &__form {
        padding-top: 1rem;
        padding-bottom: 1rem;
        max-width: 300px;
    }

    .form-control {
        box-shadow: none;
        border: none;
        width: 140px;
    }

    .row-flex {
        flex-wrap: nowrap;

        .btn {
            margin-left: 10px;
        }
    }
}

.is-top-status {
    .top-status {
        max-height: 200px;
    }

    .top-bar__status {
        background-color: $brand-secondary;
        color: #FFFFFF;

        &:before {
            border-top-color: $brand-secondary;
        }

        &:after {
            content: '\f106';
            color: #FFFFFF;
            bottom: -9px;

        }
    }
}

/* Mobile menu */
.mobile-menu {
    @include transition(left .3s);
    overflow-x: hidden;
    font-size: 16px;
    font-family: $font-family-serif;
    position: fixed;
    top: 0;
    bottom: 0;
    left: -85%;
    width: 85%;
    max-width: 660px;
    background-color: $brand-secondary;
    z-index: 200;
    overflow-y: auto;

    .nav-promo a {
        font-size: 1rem;
    }

    .megamenu__container *[class^='col-'] {
        min-width: 210px;

        li {
            margin-bottom: 12px;
        }
    }
}

body.is-mobile-menu {
    .mobile-menu__bg {
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        background-color: rgba(255, 255, 255, .8);
        z-index: 199;
        position: fixed;
    }

    .mobile-menu {
        left: 0;
    }
}

@mixin scrollbars($size, $foreground-color, $background-color: mix($foreground-color, white,  50%)) {
    // For Google Chrome
    &::-webkit-scrollbar {
        width: $size;
        height: $size;
    }

    &::-webkit-scrollbar-thumb {
        background: $foreground-color;
        border-radius: 50px;
    }

    &::-webkit-scrollbar-track {
        background: $background-color;
    }
}

//@include scrollbars(1em, red, blue);

/* Live Search */


.live-search {
    $this: &;
    display: none;
    @include scrollbars(8px, #3f9e7e, #FFFFFF);
    box-shadow: 0 3px 15px rgba(0, 0, 0, .22);
    position: absolute;
    left: 0;
    right: 0;
    top: 100%;
    margin-top: 10px;
    z-index: 101;
    background-color: #fff;
    border: 10px solid #FFFFFF;
    border-right-width: 6px;
    padding-right: 6px;
    font-size: 1rem;
    line-height: 1;
    max-height: 303px;
    overflow-y: auto;
    padding-bottom: 1px;

    &__product {
        color: inherit;
        border: 1px solid #F2F2F2;
        border-radius: 3px;
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        padding: 10px;
        margin-bottom: -1px;
        position: relative;

        &:hover {
            background-color: #F2F2F2;
            color: inherit;

            .price {
                color: $brand-secondary;
            }

        }

        > span {
            display: block;
        }

        img {
            width: 70px;
        }

        &-text {
            padding-left: 20px;

            > span {
                display: block;
            }
        }

        &-name {
            font-size: 1.25rem;
        }

        &-maker {
            color: $brand-secondary-light-text;
        }
    }
}

@media (max-width: $screen-xs-max) {
    .header-form__text {
        position: static;
    }

    .live-search {
        left: -10px;
        right: -10px;
    }
}

// TODO: Tylko css do pokazywania wyników wyszikiwania, usunać po wdrożeniu
input:focus + .live-search {
    display: block;
}