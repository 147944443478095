/* ===========================================
* Helper Class
* ======================================== */
[data-toggle-class] {
    cursor: pointer;
}

.text-secondary-light {
    color: $brand-secondary-light-text;
}

.font-size--sm {
    font-size: 11px;
}

.position--absolute {
    position: absolute;
    text-overflow: clip !important;
}

.pl-0 {
    padding-left: 0 !important;
}

.bb-1 {
    border-bottom: 1px solid $gray-lighter;
}

.divider {
    margin: .75rem 0 2rem 0;
    border-bottom: 1px solid $gray-lighter;

    &--left {
        margin-top: 2rem;
        margin-left: -20px;
    }
}

.text-light {
    color: #bdb2ae;
}

hr {
    border-bottom: 1px solid;
    border-color: #f2f6ff;
    height: 1px;

    &.line-3 {
        border-top: double #f2f6ff;
    }

    &.line-1 {
        border-top: none;
    }
}

/* Links */
.link-underline {
    text-decoration: underline;
    color: inherit;
}

.color--inherit {
    color: inherit;
}

/* Alert */
.alert {
    line-height: 1.4;
    font-size: 11px;
}

.alert--dashed {
    background-color: transparent;
    border-style: dashed;
    padding: 0 1.8rem;
    border-radius: 5px;
    font-size: 1.125rem;
    text-align: center;
    margin-bottom: 1rem;
    display: inline-block;

    &.alert-success {
        color: $state-success-text;
    }
}

.alert--icon {
    padding-left: 5rem;
    position: relative;
    min-height: 4.5rem;
    display: flex;
    align-items: center;

    i[class^="icon-"] {
        height: 3rem;
        width: 5rem;
        text-align: center;
        line-height: 3rem;
        font-size: 2.5rem;
        left: 0;
        top: 50%;
        margin-top: -1.5rem;
        position: absolute;
    }

}

/* Button */
.btn {
    white-space: normal;
    max-width: 100%;

    i {
        line-height: 1;
        font-size: .9em;
        position: relative;
        top: .051em;
    }

    i:first-child {
        padding-right: .8em;
    }
    i:last-child {
        padding-left: .8em;
    }

    border-color: transparent;

    &:hover {

    }
}

.btn-primary {
    text-shadow: 0 1px rgba(0, 0, 0, .2);
}

.btn--clear {
    background-color: transparent;
    padding: 0;
    color: $brand-secondary-light-text;
    font-size: 1rem;

    i {
        color: $brand-secondary;
    }
}

.btn-lg {
    font-size: 1.375rem;

    i {
        font-size: .8em;
    }
}

.btn-arrow {
    font-size: 1.125rem;
    padding: .3rem 1.3rem;

    i {
        font-size: .8em;

        &:first-child {
            padding-right: 1em;
        }

        &:last-child {
            padding-left: 1.5em;
        }
    }
}

.btn-icon-submit {
    padding-top: 7px;
    padding-bottom: 7px;
    line-height: 1;

    > * {
        vertical-align: middle;
    }

    i {
        font-size: 1.4em;

        &:first-child {
            padding-right: .5em;
        }
    }
}

.btn-md {
    font-size: 1rem;
}

/* List Style */
%list-promotions {
    @include reset;
    @include clearfix;
    margin-right: -20px;
    text-align: center;
    line-height: 1.1;
    margin-bottom: -20px;
    position: relative;

    li {
        width: 150px;
        float: left;
        margin-right: 20px;
        margin-bottom: 20px;
    }
}

.list-promotions {
    $this: &;
    @extend %list-promotions;

    &__inside,
    &__more {
        display: block;
    }

    &__inside {
        @include bg-sprite(149px, 105px, -60px, 0);
        padding: 15px 10px 40px 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $brand-secondary;
        font-size: 1.125rem;
        overflow: hidden;
    }

    &__more {
        text-align: center;
        padding-top: 5px;
        font-size: 11px;
        font-weight: bold;
        font-family: $font-family-sans-serif;
    }

    a:hover,
    a:focus {
        text-decoration: none !important;

        #{$this}__inside {
            background-position: -60px -105px;
        }

    }
}

%list-nav {
    @include reset();
    line-height: 1.1;

    li {
        position: relative;
        margin-left: 30px;
        margin-bottom: 5px;
        padding-top: 2px;
        padding-bottom: 2px;

        &:before {
            content: '\e905';
            font-family: icomoon;
            left: -30px;
            width: 1rem;
            margin-right: -1rem;
            position: relative;
            font-size: 1rem;
            opacity: .45;
            top: .1rem;
        }

        > a {
            &:hover:first-child {
                color: $brand-primary;

                &:before {
                    content: '\e905';
                    font-family: icomoon;
                    left: -30px;
                    width: 1rem;
                    margin-right: -1rem;
                    position: relative;
                    font-size: 1rem;
                    top: 2px;
                    color: $brand-primary;
                    float: left;
                    border-radius: 50%;
                    overflow: hidden;
                    height: 1rem;
                    line-height: 1;
                    background: #FFFFFF;
                    //box-shadow: 0 0 5px #FFFFFF;
                    float: left;
                }
            }
        }
    }

    a {
        @include link-variant($brand-secondary, '', underline);

        &.active {
            text-decoration: underline;
        }
    }
}

.list-nav {
    @extend %list-nav
}



%list-angle {
    @include reset();
    overflow: hidden;

    li {
        position: relative;
        padding-left: 1rem;
        margin-bottom: 5px;

        &:before {
            content: '\f105';
            font-family: icomoon;
            position: absolute;
            left: 0;
            font-size: .9em;
            top: .1em
        }
    }
}

.list-angle {
    @extend %list-angle
}

.list-label {
    @include reset;
    font-weight: bold;
    color: $brand-secondary;

    li {
        display: flex;
        flex-wrap: nowrap;

        &:not(:last-child) {
            margin-bottom: .55rem;
        }
    }

    &__label {
        display: inline-block;
        padding-right: 20px;
        width: 36%;
        text-align: right;
        font-weight: normal
    }

    a {
        text-decoration: underline;
    }

}

%reset {
    margin: 0;
    padding: 0;
    list-style: none;
}

%img-zoom {
    display: block;
    position: relative;
    overflow: hidden;
    text-decoration: none;

    &:before {
        content: '+';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 25px;
        height: 25px;
        background-color: $brand-primary;
        color: #ffffff;
        text-align: center;
        line-height: 25px;
        font-size: 18px;
        font-weight: 300;
        z-index: 2;
    }

    img {
        display: block;
    }

    &:after {
        @include transition(opacity .2s);
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-color: $brand-primary;
        opacity: 0;
    }

    &:hover {
        border-color: $brand-primary;

        &:after {
            opacity: .4;
        }
    }
}

%img-scale,
.image-zoom {
    overflow: hidden;

    .image_container {
        overflow: hidden;
    }

    img {
        @include transition(transform .5s ease-in-out);
        width: 100%;
    }

    &:hover {
        img {
            @include scale(1.1);
        }
    }
}

%link--mask {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    white-space: nowrap;
    overflow: hidden;
    text-indent: 200%;
}

.link--mask {
    @extend %link--mask;
}

@include keyframes(fadeIn) {
    from {
        opacity: 0;
        @include translate(0, 30px);
    }
    to {
        opacity: 1;
        @include scale(1);
        @include translate(0, 0);
    }
}

.information {
    color: #999;
    font-size: 0.9em;
}

.preloadImage {
    opacity: .5;
}

.no-border {
    border: none !important;
}

/* Table */
.bg-th--blank {
    overflow: visible;
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100%' height='100%'> <line style='stroke: #{$table-border-color};' y2='100%' x2='100%' y1='0' x1='0' stroke-width='1' /><line  style='stroke: #{$table-border-color};'  y2='100%' x2='0' y1='0' x1='100%' stroke-width='1'/></svg>");
    background-size: 100% 100%;
}

/* Background */
.bg-green {
    background-color: $color-green;
}
.bg-yellow {
    background-color: $color-yellow;
}
.bg-violet {
    background-color: $color-violet;
}
.bg-burgundy {
    background-color: $color-burgundy;
}